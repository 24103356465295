import { Row, Col, Table, Card, Form, CardGroup, ButtonGroup, Button, Modal } from "react-bootstrap"
import { useDeleteRakutenProductSearchParamsMutation, useListRakutenProductSearchParamsQuery, useRakutenCategoryListQuery, useRakutenListQuery, useRakutenMerchantsListQuery, useSaveRakutenProductSearchParamsMutation } from "../../Store/AffiliateNetwork/AffiliateNetwork.service"
import Loading from "../Loading"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Store/Store"
import { setMessage } from "../../Store/Notification/Notification.slice"
import { ApiError } from "../../Services/BaseApi"
import Notification from "../Notification"
import { IRakutenProduct, IRakutenProductSearchParams } from "@esavvynpm/types"

const apps = {
    esavvy: 'Esavvy',
    "esavvy-travel": 'Esavvy Travel',
} as const

const defaultSearchParams: Partial<IRakutenProductSearchParams> = {

} as const

function Rakuten() {

    const dispatch = useDispatch<AppDispatch>()
    const [initName, setInitName] = useState('')
    const [initApp, setInitApp] = useState(Object.keys(apps)[0])
    const [selectedSearchParamsId, setSelectedSearchParamsId] = useState<string | undefined>(undefined)
    const [showSaveSearch, setShowSaveSearch] = useState(false)
    const [payload, setPayload] = useState<{ page: number, params: Partial<IRakutenProductSearchParams> }>({
        page: 1,
        params: { ...defaultSearchParams },
    })

    const { data, isLoading, isFetching } = useRakutenListQuery(payload.params)

    const [saveSearch, { error, isError, isSuccess, isLoading: isSaving }] = useSaveRakutenProductSearchParamsMutation()
    const [deleteSearch, { error: deleteError, isError: isDeleteError, isSuccess: isDeleteSuccess, isLoading: isDeleteLoading }] = useDeleteRakutenProductSearchParamsMutation()

    useEffect(() => {
        if (isSuccess) {
            dispatch(setMessage({
                message: 'Search saved',
                variant: 'success',
            }))
            setShowSaveSearch(false)
        }
    }, [isSuccess])

    useEffect(() => {
        if (isDeleteSuccess) {
            dispatch(setMessage({
                message: 'Search deleted',
                variant: 'success',
            }))
            setSelectedSearchParamsId(undefined)
        }
    }, [isDeleteSuccess])

    useEffect(() => {
        if (isError) {
            dispatch(setMessage({
                message: (error as ApiError).data.error || (error as ApiError).status.toString(),
                variant: 'danger',
            }))
        }
    }, [isError, error])

    useEffect(() => {
        if (isDeleteError) {
            dispatch(setMessage({
                message: (deleteError as ApiError).data.error || (deleteError as ApiError).status.toString(),
                variant: 'danger',
            }))
        }
    }, [isDeleteError, deleteError])

    const onChange = (params?: Partial<IRakutenProductSearchParams>) => {
        setPayload({ page: 1, params: params ? { ...params } : { ...defaultSearchParams } })
    }

    const onDelete = (id?: string) => {
        if (!id) {
            return
        }
        const confirm = window.confirm('Are you sure you want to delete this search?')
        if (!confirm) {
            return
        }
        deleteSearch(id)
    }

    let view = <Loading />
    if (data) {
        view = <Row>
            <Col>
                <Row className="mb-3">
                    <Col xs="auto">
                        <h2>Rakuten Product Search</h2>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs="auto">
                        <h5>Filters</h5>
                    </Col>
                    <Col className="justify-content-center">
                        <SelectSearchParams
                            value={selectedSearchParamsId}
                            onSelect={setSelectedSearchParamsId}
                            onChange={onChange}
                            disabled={isLoading || isFetching || isSaving}
                        />
                    </Col>
                    <Col xs="auto" className="text-end">
                        <ButtonGroup>
                            <Button variant="primary" disabled={isLoading || isFetching || isSaving} onClick={() => setShowSaveSearch(true)}>Save</Button>
                            <Button variant="danger" disabled={isLoading || isFetching || !selectedSearchParamsId || isDeleteLoading} onClick={() => onDelete(selectedSearchParamsId)}>Delete</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col><FilterCard params={payload.params} onChange={onChange} disabled={isLoading || isFetching} /></Col>
                </Row>
                <Row>
                    <Col><ListTable items={data} /></Col>
                </Row>
            </Col>
            <EditNameAppModal
                show={showSaveSearch}
                initName={initName}
                initApp={initApp}
                onHide={() => setShowSaveSearch(false)}
                onSave={(name, app) => saveSearch({ name, app, params: payload.params })}
            />
        </Row>
    }

    return view
}

export default Rakuten

function FilterCard({
    params,
    onChange,
    disabled,
}: {
    params: Partial<IRakutenProductSearchParams>
    onChange: (params: Partial<IRakutenProductSearchParams>) => void
    disabled?: boolean
}) {

    const [keywords, setKeywords] = useState<string | undefined>(params.keywords);
    const [debounceKeywords, setDebounceKeywords] = useState<string | undefined>(params.keywords);

    const debounceValue = useDebounce(keywords, 1500);

    useEffect(() => {
        setKeywords(params.keywords);
    }, [params.keywords]);

    useEffect(() => {
        setDebounceKeywords(keywords);

        if (keywords) {
            onChange({ ...params, keywords })
        } else {
            const { keywords: _, ...rest } = params;
            onChange(rest)
        }
    }, [debounceValue]);

    const setCategories = (categories?: string[]) => {
        if (categories) {
            onChange({ ...params, categories })
        } else {
            const { categories: _, ...rest } = params;
            onChange(rest)
        }
    }

    const setAdvertisers = (merchantIds?: string[]) => {
        if (merchantIds) {
            onChange({ ...params, merchantIds })
        } else {
            const { merchantIds: _, ...rest } = params;
            onChange(rest)
        }
    }

    const setHasImage = (hasImage?: boolean) => {
        if (hasImage) {
            onChange({ ...params, hasImage })
        } else {
            const { hasImage: _, ...rest } = params;
            onChange(rest)
        }
    }

    return (
        <Row>
            <Col>
                <Row className="mb-3">
                    <Col>
                        <Card>
                            <Card.Body>
                                <FilterKeywords value={keywords} onChange={setKeywords} />
                                <FilterHasImage value={params.hasImage} onChange={setHasImage} disabled={disabled} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardGroup>
                            <Card>
                                <Card.Body>
                                    <FilterCategory value={params.categories} onChange={setCategories} disabled={disabled} />
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <FilterAdvertisers value={params.merchantIds} onChange={setAdvertisers} disabled={disabled} />
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

function FilterHasImage({
    value,
    onChange,
    disabled,
}: {
    value?: boolean
    onChange: (hasImage?: boolean) => void
    disabled?: boolean
}) {

    return (
        <Form.Group className="row">
            <Col xs="auto"><Form.Check type="checkbox" onChange={(e) => onChange(e.target.checked)} value={value ? "true" : "false"} disabled={disabled} /></Col>
            <Col><Form.Label htmlFor="hasImage">Has Image</Form.Label></Col>
        </Form.Group>
    )
}

function FilterCategory({
    value,
    onChange,
    disabled,
}: {
    value?: string[]
    onChange: (categories?: string[]) => void
    disabled?: boolean
}) {

    const [categories, setCategories] = useState<string[]>([])
    const { data, isLoading, isFetching } = useRakutenCategoryListQuery()

    useEffect(() => {
        if (data) {
            const sortedCategories = [...data].sort((a, b) => {
                return a.localeCompare(b)
            })
            setCategories(sortedCategories)
        }
    }, [data])

    return (
        <Form.Group>
            <Form.Label>Categories</Form.Label>
            <Form.Select multiple size="sm" htmlSize={10} onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value)
                onChange(selectedOptions.length ? selectedOptions : undefined)
            }} value={value} disabled={disabled || isLoading || isFetching}>
                {!categories?.length ? <option value="">Loading...</option> : categories
                    .map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    ))}
            </Form.Select>
        </Form.Group>
    )
}

function FilterKeywords({
    value,
    onChange,
}: {
    value?: string
    onChange: (keywords?: string) => void
}) {

    return (
        <Form.Group>
            <Form.Label>Keywords</Form.Label>
            <Form.Control type="text" onChange={(e) => onChange(e.target.value)} value={value} />
        </Form.Group>
    )
}

function FilterAdvertisers({
    value,
    onChange,
    disabled,
}: {
    value?: string[]
    onChange: (advertisers?: string[]) => void
    disabled?: boolean
}) {

    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const { data: merchantIds, isLoading: isMerchantsLoading } = useRakutenMerchantsListQuery()

    const [merchants, setMerchants] = useState<{ id: string, name: string, network: string }[]>([])

    useEffect(() => {
        if (merchantKeys && merchantIds) {
            setMerchants([...merchantIds]
                .sort((a, b) => merchantKeys[a].name.localeCompare(merchantKeys[b].name))
                .map((merchantId) => ({
                    id: merchantId, name: merchantKeys[merchantId].name, network: merchantKeys[merchantId].network
                })))
        }
    }, [merchantKeys, merchantIds])

    return (
        <Form.Group>
            <Form.Label>Merchants</Form.Label>
            <Form.Select multiple size="sm" htmlSize={10} onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                onChange(selectedOptions.length ? selectedOptions : undefined);
            }} value={value} disabled={disabled}>
                {merchants.map((merchant, index) => (
                    <option key={index} value={merchant.id}>{merchant.name}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )
}

function EditNameAppModal({
    show,
    initName,
    initApp,
    onSave,
    onHide,
}: {
    show: boolean
    initName: string
    initApp: string
    onSave: (name: string, app: string) => void
    onHide: () => void
}) {

    const [name, setName] = useState(initName)
    const [app, setApp] = useState(initApp)

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Save Search Params</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Notification />
            <Form.Group>
                <Form.Label>Search Name</Form.Label>
                <Form.Control
                    type="text"
                    autoFocus={true}
                    value={name}
                    required={true}
                    onChange={event => setName(event.target.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>App</Form.Label>
                <Form.Select onChange={(e) => setApp(e.target.value)} value={app}>
                    {Object.entries(apps).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </Form.Select>
            </Form.Group>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Close</Button>
            <Button variant="primary" onClick={() => onSave(name, app)} disabled={!name}>Save changes</Button>
        </Modal.Footer>
    </Modal>
}

function SelectSearchParams({
    value,
    onSelect,
    onChange,
    disabled,
}: {
    value?: string
    onSelect: (id: string) => void
    onChange: (params?: Partial<IRakutenProductSearchParams>) => void
    disabled?: boolean
}) {

    const { data, isLoading, isFetching } = useListRakutenProductSearchParamsQuery()

    const onSelectChange = (id: string) => {
        const savedParams = data?.find(param => param._id === id)
        onChange(savedParams)
        onSelect(id)
    }

    return (
        <Form.Group>
            <Form.Select onChange={(e) => onSelectChange(e.target.value)} value={value} disabled={disabled || isLoading || isFetching}>
                <option value="">Select saved search params</option>
                {data?.map((searchParam, index) => (
                    <option key={index} value={searchParam._id}>{searchParam.name}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )
}

function ListTable({
    items,
}: {
    items: {
        results: IRakutenProduct[]
        total: number
        totalPages: number
        page: number
        itemsPerPage: number
        hasPrevPage: boolean
        hasNextPage: boolean
    }
}) {

    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    return (
        <Table
            responsive
            size="sm"
            striped
        >
            <thead>
                <tr>
                    <th>#</th>
                    <th>Advertiser</th>
                    <th>Category</th>
                    <th>Name</th>
                    <th>Image</th>
                </tr>
            </thead>
            <tbody>
                {items?.results.map((item, index) => (
                    <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{merchantKeys?.[item.merchantId]?.name}</td>
                        <td>{item.category}</td>
                        <td>{item.title}</td>
                        <td className="text-center"><img src={item.imageUrl} style={{ maxWidth: 100, maxHeight: 100 }} /></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

function useDebounce(cb: string | undefined, delay: number) {
    const [debounceValue, setDebounceValue] = useState(cb);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(cb);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [cb, delay]);
    return debounceValue;
}