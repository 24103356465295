import { useDispatch } from "react-redux"
import LocalStorageService, { LocalStorageKeys } from "../../Services/LocalStorage"
import { useSignInWithEsavvyMutation } from "../../Store/Authorization/Authorization.service"
import SignInForm from "./form"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Notification/Notification.slice"
import { ApiError } from "../../Services/BaseApi"
import logo from "../../Images/logo.png"
import { Col, Container, Row } from "react-bootstrap"
import { setToken } from "../../Store/Authorization/Authorization.slice"
import Notification from "../Notification"
function SignIn() {

    const dispatch = useDispatch<AppDispatch>()
    const [signInWithEsavvy, signInWithEsavvyResult] = useSignInWithEsavvyMutation()

    let isLoading = signInWithEsavvyResult.isLoading

    const onPressSignInWithEsavvy = async (credentials: {
        email: string
        password: string
    }) => {
        try {
            const token = await signInWithEsavvy(credentials).unwrap()
            if (!token) {
                throw new Error('Invalid credentials')
            }
            await LocalStorageService.setItem(LocalStorageKeys.AUTH_TOKEN, JSON.stringify(token))
            dispatch(setToken(token))
            dispatch(setMessage({
                message: 'Sign in successfull',
                variant: 'success',
            }))
        } catch (err) {
            dispatch(setMessage({
                message: (err as ApiError).data?.error,
                variant: 'danger',
            }))
        }
    }

    return (
        <Container>
            <Row className="mb-3">
                <Col xs={12} md={4} className="mx-auto text-center pt-5">
                    <img src={logo} alt="eSavvy" width={100} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className="mx-auto mb-3">
                    <SignInForm
                        className="mx-auto"
                        disabled={isLoading}
                        handleSubmit={credentials => onPressSignInWithEsavvy(credentials)}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className="mx-auto">
                    <Notification />
                </Col>
            </Row>
        </Container>
    )
}

export default SignIn
