import { IFeedComposition } from "@esavvynpm/types";
import { useDeleteFeedCompositionMutation, useListFeedCompositionQuery, useUpdateFeedCompositionMutation } from "../../Store/Feed/Feed.service";
import { Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import { DateService } from "../../Services/date";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { AppDispatch } from "../../Store/Store";
import { useDispatch } from "react-redux";
import { ApiError } from "../../Services/BaseApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencil, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";

function FeedComposition() {

    const { data: feedComposition } = useListFeedCompositionQuery();

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <h4>Feed Composition</h4>
                    </Col>
                    <Col xs="auto">
                        <Link to="/feed-composition-add">
                            <Button variant="primary">Add</Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListTable items={feedComposition} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default FeedComposition;

function ListTable({
    items,
}: {
    items: IFeedComposition[] | undefined
}) {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const [deleteFeedComposition, { isLoading, isSuccess, isError, error }] = useDeleteFeedCompositionMutation();
    const [updateFeedComposition, { isLoading: isUpdating, isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError }] = useUpdateFeedCompositionMutation();

    useEffect(() => {
        if (isSuccess) {
            dispatch(setMessage({ message: "Feed composition deleted successfully", variant: "success" }));
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            dispatch(setMessage({ message: (error as ApiError).data.error || (error as ApiError).status.toString(), variant: "danger" }));
        }
    }, [isError, error]);

    useEffect(() => {
        if (isUpdateSuccess) {
            dispatch(setMessage({ message: "Feed composition updated successfully", variant: "success" }));
        }
    }, [isUpdateSuccess]);

    useEffect(() => {
        if (isUpdateError) {
            dispatch(setMessage({ message: (updateError as ApiError).data.error || (updateError as ApiError).status.toString(), variant: "danger" }));
        }
    }, [isUpdateError, updateError]);

    const onDelete = (compositionId: string) => {
        const confirm = window.confirm("Are you sure you want to delete this feed composition?");
        if (confirm) {
            deleteFeedComposition(compositionId)
        }
    }

    const enableComposition = (compositionId: string) => {
        updateFeedComposition({
            id: compositionId,
            body: { status: "enabled" }
        })
    }

    const disableComposition = (compositionId: string) => {
        updateFeedComposition({
            id: compositionId,
            body: { status: "disabled" }
        })
    }

    return (
        <Table
            responsive
            size="sm"
            striped
        >
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Count</th>
                    <th>App</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {items?.map((item, index) => (
                    <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.searchParams.reduce((acc, curr) => acc + curr.count, 0)}</td>
                        <td>{item.app}</td>
                        <td>{item.status}</td>
                        <td>{DateService.formatDate(item.createdAt)}</td>
                        <td>
                            <ButtonGroup>
                                <Button variant="danger" size="sm" onClick={() => onDelete(item._id)} disabled={isLoading || isUpdating}><FontAwesomeIcon icon={faTrashCan} /></Button>
                                {item.status === "disabled" && <Button variant="success" size="sm" onClick={() => enableComposition(item._id)} disabled={isLoading || isUpdating}><FontAwesomeIcon icon={faCheck} /></Button>}
                                {item.status === "enabled" && <Button variant="secondary" size="sm" onClick={() => disableComposition(item._id)} disabled={isLoading || isUpdating}><FontAwesomeIcon icon={faXmark} /></Button>}
                                <Button variant="primary" size="sm" onClick={() => navigate(`/feed-composition/${item._id}`)} disabled={isLoading || isUpdating}><FontAwesomeIcon icon={faPencil} /></Button>
                            </ButtonGroup>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}
