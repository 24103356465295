import { ICjProduct, ICjProductSearchParams, IRakutenProduct, IRakutenProductSearchParams } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listCjLinkSearchParams: build.query<ICjProductSearchParams[], void>({
            query: () => ({
                url: API_URLS.cjProductSearchParams,
                method: 'GET',
            }),
            providesTags: ["CJLinkSearchParams"]
        }),
        saveCjLinkSearchParams: build.mutation<ICjProductSearchParams, { name: string, app: string, params: Partial<ICjProductSearchParams> }>({
            query: (body) => ({
                url: API_URLS.cjProductSearchParams,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["CJLinkSearchParams"]
        }),
        deleteCjLinkSearchParams: build.mutation<ICjProductSearchParams, string>({
            query: (id) => ({
                url: API_URLS.cjProductSearchParamsId.replace(':id', id),
                method: 'DELETE',
            }),
            invalidatesTags: ["CJLinkSearchParams"]
        }),
        cjTypesList: build.query<string[], void>({
            query: () => ({
                url: API_URLS.cjTypes,
                method: 'GET',
            }),
            providesTags: ["CJTypesList"]
        }),
        cjPromotionTypesList: build.query<string[], void>({
            query: () => ({
                url: API_URLS.cjPromotionTypes,
                method: 'GET',
            }),
            providesTags: ["CJPromotionTypesList"]
        }),
        cjLanguagesList: build.query<string[], void>({
            query: () => ({
                url: API_URLS.cjLanguages,
                method: 'GET',
            }),
            providesTags: ["CJLanguagesList"]
        }),
        cjCategoryList: build.query<string[], void>({
            query: () => ({
                url: API_URLS.cjCategories,
                method: 'GET',
            }),
            providesTags: ["CJCategoriesList"]
        }),
        cjMerchantsList: build.query<string[], void>({
            query: () => ({
                url: API_URLS.cjMerchants,
                method: 'GET',
            }),
            providesTags: ["CJMerchantsList"]
        }),
        cjList: build.query<{
            results: ICjProduct[]
            total: number
            totalPages: number
            page: number
            itemsPerPage: number
            hasPrevPage: boolean
            hasNextPage: boolean
        }, Partial<ICjProductSearchParams>>({
            query: (params) => {
                const queryParams = new URLSearchParams()
                Object.entries(params).forEach(([key, value]) => {
                    if (value !== undefined) {
                        queryParams.append(key, String(value))
                    }
                })
                return {
                    url: `${API_URLS.cjProducts}?${queryParams.toString()}`,
                    method: 'GET',
                }
            },
            providesTags: ["CJList"]
        }),
        rakutenList: build.query<{
            results: IRakutenProduct[]
            total: number
            totalPages: number
            page: number
            itemsPerPage: number
            hasPrevPage: boolean
            hasNextPage: boolean
        }, Partial<IRakutenProductSearchParams>>({
            query: (params) => {
                const queryParams = new URLSearchParams()
                Object.entries(params).forEach(([key, value]) => {
                    if (value !== undefined) {
                        queryParams.append(key, String(value))
                    }
                })
                return {
                    url: `${API_URLS.rakutenProducts}?${queryParams.toString()}`,
                    method: 'GET',
                }
            },
            providesTags: ["RakutenList"]
        }),
        rakutenCategoryList: build.query<string[], void>({
            query: () => ({
                url: API_URLS.rakutenCategoryList,
                method: 'GET',
            }),
            providesTags: ["RakutenCategoryList"]
        }),
        rakutenMerchantsList: build.query<string[], void>({
            query: () => ({
                url: API_URLS.rakutenMerchants,
                method: 'GET',
            }),
            providesTags: ["RakutenMerchantsList"]
        }),
        listRakutenProductSearchParams: build.query<IRakutenProductSearchParams[], void>({
            query: () => ({
                url: API_URLS.rakutenProductSearchParams,
                method: 'GET',
            }),
            providesTags: ["RakutenProductSearchParams"]
        }),
        saveRakutenProductSearchParams: build.mutation<IRakutenProductSearchParams, { name: string, app: string, params: Partial<IRakutenProductSearchParams> }>({
            query: (body) => ({
                url: API_URLS.rakutenProductSearchParams,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["RakutenProductSearchParams"]
        }),
        deleteRakutenProductSearchParams: build.mutation<IRakutenProductSearchParams, string>({
            query: (id) => ({
                url: API_URLS.rakutenProductSearchParamsId.replace(':id', id),
                method: 'DELETE',
            }),
            invalidatesTags: ["RakutenProductSearchParams"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useSaveCjLinkSearchParamsMutation,
    useListCjLinkSearchParamsQuery,
    useDeleteCjLinkSearchParamsMutation,
    useCjListQuery,
    useRakutenListQuery,
    useRakutenCategoryListQuery,
    useListRakutenProductSearchParamsQuery,
    useSaveRakutenProductSearchParamsMutation,
    useDeleteRakutenProductSearchParamsMutation,
    useRakutenMerchantsListQuery,
    useCjMerchantsListQuery,
    useCjCategoryListQuery,
    useCjTypesListQuery,
    useCjPromotionTypesListQuery,
    useCjLanguagesListQuery,
} = api