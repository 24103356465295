import { BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Config } from '../Config'
import LocalStorageService, { LocalStorageKeys } from './LocalStorage'
import { logoutAsync } from '../Store/Authorization/Authorization.slice'
import { IOAuth2Token } from '@esavvynpm/types'

export type ApiError = {
  data: {
    error: string
  },
  status: number
}

const originalBaseQuery = async function (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) {

  let baseQuery = fetchBaseQuery({
    baseUrl: Config.SERVER_BASE_URL,
    prepareHeaders: async (headers) => {
      if (!headers.has('Authorization')) {
        const tokenStr: string | null = await LocalStorageService.getItem(LocalStorageKeys.AUTH_TOKEN)
        if (tokenStr) {
          let token: IOAuth2Token | null = null
          try {
            token = JSON.parse(tokenStr)
          } catch (err) {
            console.warn((err as Error).message)
          }
          if (token) {
            headers.set('Authorization', `Bearer ${token.accessToken}`)
          }
        }
      }
      return headers
    },
  })
  if (api.endpoint === 'signInWithEsavvy' || api.endpoint === 'refreshToken') {
    baseQuery = fetchBaseQuery({
      baseUrl: Config.ACCOUNT_BASE_URL,
      prepareHeaders: async (headers) => {
        if (!headers.has('Authorization')) {
          headers.set('Authorization', `Basic ${process.env.REACT_APP_OAUTH2_CLIENT_CREDENTIALS}`)
        }
        return headers
      },
    })
  }
  const result = await baseQuery(args, api, extraOptions)

  if (result.meta?.response?.status === 401) {
    api.dispatch(logoutAsync())
  }

  return result
}

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  const result = await originalBaseQuery(args, api, extraOptions)
  if (result.error) {
    if (result.error.status === 401 || typeof result.error.status !== 'number') {
      api.dispatch(logoutAsync())
    }
  }
  return result
}

const wrappedBaseQuery = (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  if (Config.isDev()) {
    console.log("api call", args)
  }
  return baseQueryWithReauth(args, api, extraOptions)
}

export const baseSplitApi = createApi({
  baseQuery: wrappedBaseQuery,
  tagTypes: [
    "ListUsers",
    "GetUser",
    "GetUserInfluencerProfile",
    "ListMerchants",
    "GetMerchant",
    "GetMerchantTerms",
    "ListMerchantScraps",
    "ListScheduledProducts",
    "ListAnos",
    "GetAno",
    "ListRecommendations",
    "GetRecommendation",
    "ListMerchantCategories",
    "GetMerchantCategory",
    "CJPage",
    "CJLinkSearchParams",
    "CJList",
    "RakutenList",
    "RakutenCategoryList",
    "RakutenProductSearchParams",
    "FeedComposition",
    "ListFeedComposition",
    "RakutenMerchantsList",
    "CJMerchantsList",
    "CJCategoriesList",
    "CJTypesList",
    "CJPromotionTypesList",
    "CJLanguagesList",
  ],
  endpoints: () => ({}),
})