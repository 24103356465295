import { IFeedCompositionSearchParams } from "@esavvynpm/types";
import { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useListCjLinkSearchParamsQuery } from "../../Store/AffiliateNetwork/AffiliateNetwork.service";
import { useListRakutenProductSearchParamsQuery } from "../../Store/AffiliateNetwork/AffiliateNetwork.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { AppsType, Apps } from "@esavvynpm/types/dist/types/apps";
import { useAddFeedCompositionMutation } from "../../Store/Feed/Feed.service";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../Store/Store";
import { useDispatch } from "react-redux";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { ApiError } from "../../Services/BaseApi";

function FeedCompositionAdd() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const [name, setName] = useState<string>("");
    const [app, setApp] = useState<AppsType>("esavvy");
    const [searchParams, setSearchParams] = useState<IFeedCompositionSearchParams[]>([]);

    const [addFeedComposition, { isLoading, isSuccess, isError, error }] = useAddFeedCompositionMutation();

    useEffect(() => {
        if (isSuccess) {
            setName("");
            setApp("esavvy");
            setSearchParams([]);
            navigate("/feed?tab=/feed-composition");
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            dispatch(setMessage({
                message: (error as ApiError).data.error || (error as ApiError).status.toString(),
                variant: 'danger',
            }))
        }
    }, [isError, error]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Row className="mb-3">
                        <Col>
                            <h4>Add Feed Composition</h4>
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button variant="primary" onClick={() => addFeedComposition({
                                    name,
                                    app,
                                    searchParams,
                                    status: "disabled",
                                })} disabled={!name || !app || searchParams.length === 0 || isLoading}>Save</Button>
                                <Button variant="secondary" onClick={() => navigate("/feed?tab=/feed-composition")} disabled={isLoading}>Cancel</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <CompositionDetails
                                name={name}
                                app={app}
                                setName={setName}
                                setApp={setApp}
                                disabled={isLoading}
                            />
                        </Col>
                        <Col>
                            <SearchParamSelection
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                disabled={isLoading}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default FeedCompositionAdd

function CompositionDetails({
    name,
    app,
    setName,
    setApp,
    disabled,
}: {
    name: string
    app: AppsType | undefined
    setName: (name: string) => void
    setApp: (app: AppsType) => void
    disabled: boolean
}) {
    return (
        <Card>
            <Card.Header>
                Composition Details
            </Card.Header>
            <Card.Body>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control autoFocus type="text" placeholder="Composition Name" value={name} onChange={(e) => setName(e.target.value)} disabled={disabled} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>App</Form.Label>
                    <Form.Select value={app} onChange={(e) => setApp(e.target.value as AppsType)} disabled={disabled}>
                        {Object.values(Apps).map((app) => (
                            <option key={app} value={app}>{app}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Card.Body>
        </Card>
    )
}

function SearchParamSelection({
    searchParams,
    setSearchParams,
    disabled,
}: {
    searchParams: IFeedCompositionSearchParams[]
    setSearchParams: (searchParams: IFeedCompositionSearchParams[]) => void
    disabled: boolean
}) {

    const { data: rakutenSearchParams } = useListRakutenProductSearchParamsQuery();
    const { data: cjSearchParams } = useListCjLinkSearchParamsQuery();

    const searchParamsNameIndex = useMemo(() => {
        const index: Record<string, string> = {};
        rakutenSearchParams?.forEach((param) => {
            index[param._id] = param.name;
        });
        cjSearchParams?.forEach((param) => {
            index[param._id] = param.name;
        });
        return index;
    }, [rakutenSearchParams, cjSearchParams]);

    const onAdd = (params: IFeedCompositionSearchParams) => {
        setSearchParams([...searchParams, params]);
    }

    const onRemove = (index: number) => {
        setSearchParams(searchParams.filter((_, i) => i !== index));
    }

    const [selectedRakuten, setSelectedRakuten] = useState<{
        id: string
        count: number
    } | undefined>(undefined);
    const [selectedCj, setSelectedCj] = useState<{
        id: string
        count: number
    } | undefined>(undefined);

    const onAddRakuten = () => {
        if (selectedRakuten) {
            onAdd({
                type: "rakuten",
                id: selectedRakuten.id,
                count: selectedRakuten.count,
            });
        }
    }

    const onAddCj = () => {
        if (selectedCj) {
            onAdd({
                type: "cj",
                id: selectedCj.id,
                count: selectedCj.count,
            });
        }
    }

    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        Search Params
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-3">
                            <Col xs="auto" className="text-nowrap">
                                <Form.Label>Rakuten</Form.Label>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Select value={selectedRakuten?.id} onChange={(e) => setSelectedRakuten({
                                        id: e.target.value,
                                        count: selectedRakuten?.count || 0,
                                    })} disabled={disabled}>
                                        <option value="">Select Rakuten</option>
                                        {rakutenSearchParams?.map((param) => (
                                            <option key={param._id} value={param._id}>{param.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group>
                                    <Form.Control type="number" value={selectedRakuten?.count || 0} onChange={(e) => setSelectedRakuten({
                                        id: selectedRakuten?.id || "",
                                        count: !!e.target.value && !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0,
                                    })} disabled={disabled} />
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Button variant="primary" onClick={() => onAddRakuten()} disabled={!selectedRakuten || selectedRakuten.count <= 0 || disabled}>Add</Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs="auto" className="text-nowrap">
                                <Form.Label>CJ</Form.Label>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Select value={selectedCj?.id} onChange={(e) => setSelectedCj({
                                        id: e.target.value,
                                        count: selectedCj?.count || 0,
                                    })} disabled={disabled}>
                                        <option value="">Select CJ</option>
                                        {cjSearchParams?.map((param) => (
                                            <option key={param._id} value={param._id}>{param.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group>
                                    <Form.Control type="number" value={selectedCj?.count || 0} onChange={(e) => setSelectedCj({
                                        id: selectedCj?.id || "",
                                        count: !!e.target.value && !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0,
                                    })} disabled={disabled} />
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Button variant="primary" onClick={() => onAddCj()} disabled={!selectedCj || selectedCj.count <= 0 || disabled}>Add</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ListTable
                                    items={searchParams}
                                    searchParamsNameIndex={searchParamsNameIndex}
                                    onRemove={onRemove}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

function ListTable({
    items,
    searchParamsNameIndex,
    onRemove,
    disabled,
}: {
    items: IFeedCompositionSearchParams[]
    searchParamsNameIndex: Record<string, string>
    onRemove: (index: number) => void
    disabled: boolean
}) {
    return (
        <Table
            responsive
            size="sm"
            striped
        >
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Count</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {items?.map((item, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{searchParamsNameIndex[item.id]}</td>
                        <td>{item.count}</td>
                        <td>
                            <Button variant="secondary" size="sm" onClick={() => onRemove(index)} disabled={disabled}><FontAwesomeIcon icon={faTrashCan} /></Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}
