import { Row, Col, Table, Card, Form, CardGroup, ButtonGroup, Button, Modal } from "react-bootstrap"
import { useCjCategoryListQuery, useCjLanguagesListQuery, useCjListQuery, useCjMerchantsListQuery, useCjPromotionTypesListQuery, useCjTypesListQuery, useDeleteCjLinkSearchParamsMutation, useListCjLinkSearchParamsQuery, useSaveCjLinkSearchParamsMutation } from "../../Store/AffiliateNetwork/AffiliateNetwork.service"
import Loading from "../Loading"
import { CjPromotionTypesType } from "../../types.cj"
import { useEffect, useState } from "react"
import { CjLinkCategoryType, CjLinkLangsType, CjLinkTypesType, ICjProductSearchParams } from "@esavvynpm/types"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Store/Store"
import { setMessage } from "../../Store/Notification/Notification.slice"
import { ApiError } from "../../Services/BaseApi"
import Notification from "../Notification"
import { CjListPaginatedResults } from "../../Store/Types"

const apps = {
    esavvy: 'Esavvy',
    "esavvy-travel": 'Esavvy Travel',
} as const

const defaultSearchParams: Partial<ICjProductSearchParams> = {} as const

function Dlh() {

    const dispatch = useDispatch<AppDispatch>()
    const [initName, setInitName] = useState('')
    const [initApp, setInitApp] = useState(Object.keys(apps)[0])
    const [selectedSearchParamsId, setSelectedSearchParamsId] = useState<string | undefined>(undefined)
    const [showSaveSearch, setShowSaveSearch] = useState(false)
    const [payload, setPayload] = useState<{ page: number, params: Partial<ICjProductSearchParams> }>({
        page: 1,
        params: { ...defaultSearchParams },
    })

    const { data, isLoading, isFetching } = useCjListQuery(payload.params)

    const [saveSearch, { error, isError, isSuccess, isLoading: isSaving }] = useSaveCjLinkSearchParamsMutation()
    const [deleteSearch, { error: deleteError, isError: isDeleteError, isSuccess: isDeleteSuccess, isLoading: isDeleteLoading }] = useDeleteCjLinkSearchParamsMutation()

    useEffect(() => {
        if (isSuccess) {
            dispatch(setMessage({
                message: 'Search saved',
                variant: 'success',
            }))
            setShowSaveSearch(false)
        }
    }, [isSuccess])

    useEffect(() => {
        if (isDeleteSuccess) {
            dispatch(setMessage({
                message: 'Search deleted',
                variant: 'success',
            }))
            setSelectedSearchParamsId(undefined)
        }
    }, [isDeleteSuccess])

    useEffect(() => {
        if (isError) {
            dispatch(setMessage({
                message: (error as ApiError).data.error || (error as ApiError).status.toString(),
                variant: 'danger',
            }))
        }
    }, [isError, error])

    useEffect(() => {
        if (isDeleteError) {
            dispatch(setMessage({
                message: (deleteError as ApiError).data.error || (deleteError as ApiError).status.toString(),
                variant: 'danger',
            }))
        }
    }, [isDeleteError, deleteError])

    const onChange = (params?: Partial<ICjProductSearchParams>) => {
        setPayload({ page: 1, params: params ? { ...params } : { ...defaultSearchParams } })
    }

    const onDelete = (id?: string) => {
        if (!id) {
            return
        }
        const confirm = window.confirm('Are you sure you want to delete this search?')
        if (!confirm) {
            return
        }
        deleteSearch(id)
    }

    let view = <Loading />
    if (data) {
        view = <Row>
            <Col>
                <Row className="mb-3">
                    <Col xs="auto">
                        <h2>CJ Product Search</h2>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs="auto">
                        <h5>Filters</h5>
                    </Col>
                    <Col className="justify-content-center">
                        <SelectSearchParams
                            value={selectedSearchParamsId}
                            onSelect={setSelectedSearchParamsId}
                            onChange={onChange}
                            disabled={isLoading || isFetching || isSaving}
                        />
                    </Col>
                    <Col xs="auto" className="text-end">
                        <ButtonGroup>
                            <Button variant="primary" disabled={isLoading || isFetching || isDeleteLoading} onClick={() => setShowSaveSearch(true)}>Save</Button>
                            <Button variant="danger" disabled={isLoading || isFetching || !selectedSearchParamsId || isDeleteLoading} onClick={() => onDelete(selectedSearchParamsId)}>Delete</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col><FilterCard params={payload.params} onChange={onChange} disabled={isLoading || isFetching} /></Col>
                </Row>
                <Row>
                    <Col><ListTable items={data} /></Col>
                </Row>
            </Col>
            <EditNameAppModal
                show={showSaveSearch}
                initName={initName}
                initApp={initApp}
                onHide={() => setShowSaveSearch(false)}
                onSave={(name, app) => saveSearch({ name, app, params: payload.params })}
            />
        </Row>
    }

    return view
}

export default Dlh

function FilterCard({
    params,
    onChange,
    disabled,
}: {
    params: Partial<ICjProductSearchParams>
    onChange: (params: Partial<ICjProductSearchParams>) => void
    disabled?: boolean
}) {

    const [keywords, setKeywords] = useState<string | undefined>(params.keywords);
    const [debounceKeywords, setDebounceKeywords] = useState<string | undefined>(params.keywords);

    const debounceValue = useDebounce(keywords, 1500);

    useEffect(() => {
        setKeywords(params.keywords);
    }, [params.keywords]);

    useEffect(() => {
        setDebounceKeywords(keywords);

        if (keywords) {
            onChange({ ...params, keywords })
        } else {
            const { keywords: _, ...rest } = params;
            onChange(rest)
        }
    }, [debounceValue]);

    const setType = (types?: CjLinkTypesType[]) => {
        if (types) {
            onChange({ ...params, types })
        } else {
            const { types: _, ...rest } = params;
            onChange(rest)
        }
    }

    const setCategory = (categories?: CjLinkCategoryType[]) => {
        if (categories) {
            onChange({ ...params, categories })
        } else {
            const { categories: _, ...rest } = params;
            onChange(rest)
        }
    }

    const setLanguage = (langs?: CjLinkLangsType[]) => {
        if (langs) {
            onChange({ ...params, langs })
        } else {
            const { langs: _, ...rest } = params;
            onChange(rest)
        }
    }

    const setPromotionType = (promotionTypes?: CjPromotionTypesType[]) => {
        if (promotionTypes) {
            onChange({ ...params, promotionTypes })
        } else {
            const { promotionTypes: _, ...rest } = params;
            onChange(rest)
        }
    }

    const setAdvertisers = (merchantIds?: string[]) => {
        if (merchantIds) {
            onChange({ ...params, merchantIds })
        } else {
            const { merchantIds: _, ...rest } = params;
            onChange(rest)
        }
    }

    const setHasImage = (hasImage?: boolean) => {
        if (hasImage) {
            onChange({ ...params, hasImage })
        } else {
            const { hasImage: _, ...rest } = params;
            onChange(rest)
        }
    }

    return (
        <Row>
            <Col>
                <Row className="mb-3">
                    <Col>
                        <Card>
                            <Card.Body>
                                <FilterKeywords value={keywords} onChange={setKeywords} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardGroup>
                            <Card>
                                <Card.Body>
                                    <FilterHasImage value={params.hasImage} onChange={setHasImage} disabled={disabled} />
                                    <FilterCategory value={params.categories} onChange={setCategory} disabled={disabled} />
                                    <Row>
                                        <Col><FilterPromotionType value={params.promotionTypes} onChange={setPromotionType} disabled={disabled} /></Col>
                                        <Col><FilterLanguage value={params.langs} onChange={setLanguage} disabled={disabled} /></Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <FilterAdvertisers value={params.merchantIds} onChange={setAdvertisers} disabled={disabled} />
                                    <FilterType value={params.types} onChange={setType} disabled={disabled} />
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

function FilterHasImage({
    value,
    onChange,
    disabled,
}: {
    value?: boolean
    onChange: (hasImage?: boolean) => void
    disabled?: boolean
}) {

    return (
        <Form.Group className="row">
            <Col xs="auto"><Form.Check type="checkbox" onChange={(e) => onChange(e.target.checked)} value={value ? "true" : "false"} disabled={disabled} /></Col>
            <Col><Form.Label htmlFor="hasImage">Has Image</Form.Label></Col>
        </Form.Group>
    )
}

function FilterType({
    value,
    onChange,
    disabled,
}: {
    value?: CjLinkTypesType[]
    onChange: (type?: CjLinkTypesType[]) => void
    disabled?: boolean
}) {

    const [types, setTypes] = useState<string[]>([])
    const { data, isLoading, isFetching } = useCjTypesListQuery()

    useEffect(() => {
        if (data) {
            const sortedTypes = [...data].sort((a, b) => {
                return a.localeCompare(b)
            })
            setTypes(sortedTypes)
        }
    }, [data])

    return (
        <Form.Group>
            <Form.Label>Types</Form.Label>
            <Form.Select multiple size="sm" htmlSize={10} onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                onChange(selectedOptions.length ? selectedOptions as CjLinkTypesType[] : undefined);
            }} value={value} disabled={disabled}>
                {types.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )
}

function FilterCategory({
    value,
    onChange,
    disabled,
}: {
    value?: CjLinkCategoryType[]
    onChange: (categories?: CjLinkCategoryType[]) => void
    disabled?: boolean
}) {

    const [categories, setCategories] = useState<string[]>([])
    const { data, isLoading, isFetching } = useCjCategoryListQuery()

    useEffect(() => {
        if (data) {
            const sortedCategories = [...data].sort((a, b) => {
                return a.localeCompare(b)
            })
            setCategories(sortedCategories)
        }
    }, [data])

    return (
        <Form.Group>
            <Form.Label>Categories</Form.Label>
            <Form.Select multiple size="sm" htmlSize={10} onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                onChange(selectedOptions.length ? selectedOptions as CjLinkCategoryType[] : undefined);
            }} value={value} disabled={disabled}>
                {categories.map((category, index) => (
                    <option key={index} value={category}>{category}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )
}

function FilterLanguage({
    value,
    onChange,
    disabled,
}: {
    value?: CjLinkLangsType[]
    onChange: (languages?: CjLinkLangsType[]) => void
    disabled?: boolean
}) {

    const [languages, setLanguages] = useState<string[]>([])
    const { data, isLoading, isFetching } = useCjLanguagesListQuery()

    useEffect(() => {
        if (data) {
            const sortedLanguages = [...data].sort((a, b) => {
                return a.localeCompare(b)
            })
            setLanguages(sortedLanguages)
        }
    }, [data])

    return (
        <Form.Group>
            <Form.Label>Languages</Form.Label>
            <Form.Select multiple size="sm" htmlSize={10} onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions).map(option => +option.value);
                onChange(selectedOptions.length ? selectedOptions as CjLinkLangsType[] : undefined);
            }} value={value?.toString()} disabled={disabled}>
                {languages.map((language, index) => (
                    <option key={index} value={language}>{language}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )
}

function FilterKeywords({
    value,
    onChange,
}: {
    value?: string
    onChange: (keywords?: string) => void
}) {

    return (
        <Form.Group>
            <Form.Label>Keywords</Form.Label>
            <Form.Control type="text" onChange={(e) => onChange(e.target.value)} value={value} />
        </Form.Group>
    )
}

function FilterPromotionType({
    value,
    onChange,
    disabled,
}: {
    value?: CjPromotionTypesType[]
    onChange: (promotionTypes?: CjPromotionTypesType[]) => void
    disabled?: boolean
}) {

    const [promotionTypes, setPromotionTypes] = useState<string[]>([])
    const { data, isLoading, isFetching } = useCjPromotionTypesListQuery()

    useEffect(() => {
        if (data) {
            console.log(data)
            const sortedPromotionTypes = [...data].sort((a, b) => {
                return a.localeCompare(b)
            })
            setPromotionTypes(sortedPromotionTypes)
        }
    }, [data])

    return (
        <Form.Group>
            <Form.Label>Promotion Types</Form.Label>
            <Form.Select multiple size="sm" htmlSize={10} onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                onChange(selectedOptions.length ? selectedOptions as CjPromotionTypesType[] : undefined);
            }} value={value} disabled={disabled}>
                {promotionTypes.map((promotionType, index) => (
                    <option key={index} value={promotionType}>{promotionType}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )
}

function FilterAdvertisers({
    value,
    onChange,
    disabled,
}: {
    value?: string[]
    onChange: (advertisers?: string[]) => void
    disabled?: boolean
}) {

    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const { data: merchantIds, isLoading: isMerchantsLoading } = useCjMerchantsListQuery()

    const [merchants, setMerchants] = useState<{ id: string, name: string, network: string }[]>([])

    useEffect(() => {
        if (merchantKeys && merchantIds) {
            setMerchants([...merchantIds]
                .sort((a, b) => merchantKeys[a].name.localeCompare(merchantKeys[b].name))
                .map((merchantId) => ({
                    id: merchantId, name: merchantKeys[merchantId].name, network: merchantKeys[merchantId].network
                })))
        }
    }, [merchantKeys, merchantIds])

    return (
        <Form.Group>
            <Form.Label>Merchants</Form.Label>
            <Form.Select multiple size="sm" htmlSize={10} onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                onChange(selectedOptions.length ? selectedOptions : undefined);
            }} value={value} disabled={disabled}>
                {merchants.map((merchant, index) => (
                    <option key={index} value={merchant.id}>{merchant.name}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )
}

function EditNameAppModal({
    show,
    initName,
    initApp,
    onSave,
    onHide,
}: {
    show: boolean
    initName: string
    initApp: string
    onSave: (name: string, app: string) => void
    onHide: () => void
}) {

    const [name, setName] = useState(initName)
    const [app, setApp] = useState(initApp)

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Save Search Params</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Notification />
            <Form.Group>
                <Form.Label>Search Name</Form.Label>
                <Form.Control
                    type="text"
                    autoFocus={true}
                    value={name}
                    required={true}
                    onChange={event => setName(event.target.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>App</Form.Label>
                <Form.Select onChange={(e) => setApp(e.target.value)} value={app}>
                    {Object.entries(apps).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </Form.Select>
            </Form.Group>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Close</Button>
            <Button variant="primary" onClick={() => onSave(name, app)} disabled={!name}>Save changes</Button>
        </Modal.Footer>
    </Modal>
}

function SelectSearchParams({
    value,
    onSelect,
    onChange,
    disabled,
}: {
    value?: string
    onSelect: (id: string) => void
    onChange: (params?: Partial<ICjProductSearchParams>) => void
    disabled?: boolean
}) {

    const { data, isLoading, isFetching } = useListCjLinkSearchParamsQuery()

    const onSelectChange = (id: string) => {
        const savedParams = data?.find(param => param._id === id)
        onChange(savedParams)
        onSelect(id)
    }

    return (
        <Form.Group>
            <Form.Select onChange={(e) => onSelectChange(e.target.value)} value={value} disabled={disabled || isLoading || isFetching}>
                <option value="">Select saved search params</option>
                {data?.map((searchParam, index) => (
                    <option key={index} value={searchParam._id}>{searchParam.name}</option>
                ))}
            </Form.Select>
        </Form.Group>
    )
}

function ListTable({
    items,
}: {
    items: CjListPaginatedResults
}) {
    return (
        <Table
            responsive
            size="sm"
            striped
        >
            <thead>
                <tr>
                    <th>#</th>
                    <th>Type</th>
                    <th>Advertiser</th>
                    <th>Category</th>
                    <th>Name</th>
                    <th>Image</th>
                </tr>
            </thead>
            <tbody>
                {items?.results.map((item, index) => (
                    <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{item.cjLinkType}</td>
                        <td>{item.merchantName}</td>
                        <td>{item.category}</td>
                        <td>{item.title}</td>
                        <td className="text-center"><img src={item.imageUrl} style={{ maxWidth: 100, maxHeight: 100 }} /></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

function useDebounce(cb: string | undefined, delay: number) {
    const [debounceValue, setDebounceValue] = useState(cb);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(cb);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [cb, delay]);
    return debounceValue;
}