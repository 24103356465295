import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Nav, Row, Table } from "react-bootstrap";
import AdvTablePagination from "./pagination";
import { Link } from "react-router-dom";
import { PaginatedQueryPayload } from "../../Store/Types";
import { DateFormatType, DateService } from "../../Services/date";
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumericFormat } from 'react-number-format';
import AdvTableFilter from "./filter";
import { AdvTableFilterParams } from "./types";
import { AppsType } from "@esavvynpm/types";

function AdvTable<T extends { [index: string]: any }>(props: {
    id?: string
    idKey?: string
    headers?: {
        label: string
        key: keyof T
        type?: "string" | "date"
        dateFormat?: DateFormatType
        disableSort?: boolean
        className?: string
    }[]
    data?: {
        cells?: { [index: string]: (key: keyof T, data: T) => string | JSX.Element }
    }
    queryCallback: (args: PaginatedQueryPayload) => any
    updateQueryParams?: (args: PaginatedQueryPayload) => void
    links?: { label: string, path: string }[]
    deleteLink?: {
        callback: (id: string) => Promise<void>
        disabled: boolean
    }
    initialSort?: {
        field: string
        sort: string
    }
    filters?: AdvTableFilterParams[]
    customAggregation?: {
        label: string
        toFixed?: number
        prefix?: string
    }
}) {

    const [disabledSearch, setDisabledSearch] = useState(false)
    const [page, setPage] = useState(1)
    const [field, setField] = useState(props.initialSort ? props.initialSort.field : '_id')
    const [sort, setSort] = useState(props.initialSort ? props.initialSort.sort : 'desc')
    const [searchQuery, setSearchQuery] = useState('')
    const [tmpSearchQuery, setTmpSearchQuery] = useState('')
    const [showFilter, setShowFilter] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<AdvTableFilterParams[]>([])

    const args = {
        id: props.id,
        idKey: props.idKey,
        params: {
            page,
            searchQuery,
            field,
            sort,
            customFilters: selectedFilters,
        }
    }
    
    if (undefined !== props.updateQueryParams) {
        props.updateQueryParams(args)
    }
    const query = props.queryCallback(args)

    useEffect(() => {
        const timer = setTimeout(() => {
            setSearchQuery(tmpSearchQuery)
            clearTimeout(timer)
        }, 2000)
        return () => {
            clearTimeout(timer)
        }
    }, [tmpSearchQuery])

    useEffect(() => {
        setDisabledSearch(query.isLoading || query.isFetching)
    }, [query.isLoading, query.isFetching])

    const onSearchChange = (text: string) => {
        setTmpSearchQuery(text)
    }

    const sortColumn = (key: string) => {
        setField(key.toString())
        setSort(field !== key ? 'asc' : sort === 'asc' ? 'desc' : 'asc')
    }
    let tableWidth = props.headers?.length ?? 0
    if (props.links || props.deleteLink) {
        tableWidth++
    }

    const handleFilter = (selectedFilters: AdvTableFilterParams[]) => {
        setSelectedFilters(selectedFilters)
        setShowFilter(false)
    }

    let aggrPercentage = 0
    if (props.customAggregation && query.data?.customAggregationTotalValue) {
        const customAggregationFilteredValue = query.data?.customAggregationFilteredValue ?? 0
        const customAggregationTotalValue = query.data?.customAggregationTotalValue ?? 0
        aggrPercentage = (customAggregationFilteredValue * 100) / customAggregationTotalValue
    }

    return (
        <>
            <Row>
                <Col xs={12} md={4}>
                    {query.data?.docs.length ? <AdvTablePagination
                        query={query}
                        page={page}
                        onPageChange={(page) => setPage(page)}
                    /> : null}
                </Col>
                <Col xs={12} md={4}>
                    Showing: {query.data?.nlimit ?? 0}/<NumericFormat
                        value={query.data?.totalDocs ?? 0}
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                    />
                </Col>
                <Col xs={12} md={4} className="text-end">
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
                        <Form.Control
                            placeholder="keywords"
                            onChange={event => onSearchChange(event.target.value)}
                            disabled={disabledSearch}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                {props.filters ? <Col xs={12} md={6}>
                    <Button
                        variant="link"
                        onClick={() => setShowFilter(true)}
                    >
                        Filter
                    </Button>
                </Col> : null}
                {props.customAggregation ? <Col xs={12} md={6} className="text-end">
                    {props.customAggregation.label}: <NumericFormat
                        value={query.data?.customAggregationFilteredValue}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={props.customAggregation?.prefix}
                        decimalScale={props.customAggregation?.toFixed}
                    />/<NumericFormat
                        value={query.data?.customAggregationTotalValue}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={props.customAggregation?.prefix}
                        decimalScale={props.customAggregation?.toFixed}
                    /> (<NumericFormat
                        value={aggrPercentage}
                        displayType="text"
                        suffix={'%'}
                        decimalScale={0}
                    />)
                </Col> : null}
            </Row>
            <Row>
                <Col>
                    <Table
                        responsive={true}
                        striped={true}
                    >
                        {props.headers ? <thead>
                            <tr>
                                {props.headers.map(header => <th key={header.key.toString()}>
                                    {header.disableSort ? header.label : <Nav.Link onClick={() => sortColumn(header.key.toString())}>
                                        <>
                                            <span style={{ marginRight: 10 }}>{header.label}</span>
                                            {field !== header.key ? null : sort === 'asc' ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                                        </>
                                    </Nav.Link>}
                                </th>)}
                                {props.links || props.deleteLink ? <th>Links</th> : null}
                            </tr>
                        </thead> : null}
                        {query.isLoading ? <tbody><tr><td colSpan={tableWidth}>Loading...</td></tr></tbody> : null}
                        {query.data?.docs && !query.isLoading ? <tbody>
                            {query.data?.docs.map((record: T) => <tr key={record._id}>
                                {props.headers?.map((header, cellIndex) => {
                                    let dataStr: string | null = ''
                                    let dataElem: JSX.Element | null = null
                                    if (props.data?.cells && props.data.cells[header.key.toString()]) {
                                        const data = props.data.cells[header.key.toString()](header.key.toString(), record)
                                        if (typeof data === "string") {
                                            dataStr = data
                                        } else {
                                            dataElem = data
                                        }
                                    } else {
                                        if (header.type === "date") {
                                            dataStr = DateService.formatDate(record[header.key], header.dateFormat ?? "basic")
                                        } else {
                                            dataStr = record[header.key]
                                        }
                                    }
                                    const className = props.headers ? props.headers[cellIndex].className : undefined
                                    const key = header.key.toString()
                                    return (
                                        <td className={className} key={key}>
                                            {dataElem ? dataElem : dataStr}
                                        </td>
                                    )
                                })}
                                {props.links ? <td key={`links_${record._id}`}>
                                    {props.links.map(link => <Link key={`link_${link.path}`} style={{ marginRight: 10 }} to={link.path.replace('{id}', record['_id'])}>{link.label}</Link>)}
                                    {undefined !== props.deleteLink ? <Link to={''} onClick={(e) => {
                                        e.preventDefault()
                                        if (undefined !== props.deleteLink && !props.deleteLink.disabled) {
                                            props.deleteLink.callback(record._id)
                                        }
                                    }}>{props.deleteLink.disabled ? 'Deleting' : 'Delete'}</Link> : null}
                                </td> : null}
                            </tr>)}
                        </tbody> : null}
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    {query.data?.docs.length ? <AdvTablePagination
                        query={query}
                        page={page}
                        onPageChange={(page) => setPage(page)}
                    /> : null}
                </Col>
            </Row>
            {props.filters ? <AdvTableFilter
                show={showFilter}
                filters={props.filters}
                selectedFilters={selectedFilters}
                handleClose={() => setShowFilter(false)}
                handleFilter={handleFilter}
            /> : null}
        </>
    );
}

export default AdvTable;
