import { Container } from "react-bootstrap";
import NavPage from "../NavPage";
import Cj from "./cj";
import Rakuten from "./rakuten";
import FeedComposition from "./feed-composition";
import { useLocation } from "react-router-dom";
import FeedList from "./feed-list";
import { Apps } from "@esavvynpm/types";
function Feed() {

    const location = useLocation()
    const tab = new URLSearchParams(location.search).get('tab')

    return (
        <Container fluid>
            <NavPage
                pageTitle="Feed"
                tab={tab ?? undefined}
                items={[{
                    label: 'Rakuten Products',
                    key: '/rakuten',
                },{
                    label: 'CJ Products',
                    key: '/cj',
                }, {
                    label: 'Feed Composition',
                    key: '/feed-composition',
                }, {
                    label: 'Feed eSavvy',
                    key: '/feed-esavvy-list',
                }, {
                    label: 'Feed eSavvy Travel',
                    key: '/feed-esavvy-travel-list',
                }]}
                children={{
                    '/rakuten': <Rakuten />,
                    '/cj': <Cj />,
                    '/feed-composition': <FeedComposition />,
                    '/feed-esavvy-list': <FeedList app={Apps[0]} />,
                    '/feed-esavvy-travel-list': <FeedList app={Apps[1]} />,
                }}
            />
        </Container>
    );
}

export default Feed;
