import { Apps, AppsType } from "@esavvynpm/types";
import { useListFeedItemsQuery } from "../../Store/Feed/Feed.service";
import { Table } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import Loading from "../Loading";

function FeedList({
    app,
}: {
    app?: AppsType
}) {

    const { data, isLoading } = useListFeedItemsQuery({ page: 1, app: app ?? Apps[0] })

    let view = <Loading />
    if (!isLoading) {
        view = <Table
            responsive
            size="sm"
            striped
        >
            <thead>
                <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Category</th>
                    <th>Image</th>
                </tr>
            </thead>
            <tbody>
                {data?.docs.map((item, index) => (
                    <tr key={item._id} className="align-middle">
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td className="text-end">
                            {item.price && <div>
                                <NumericFormat
                                    value={item.price}
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix="$"
                                    decimalScale={2}
                                />
                            </div>}
                            {item.previousPrice && <div className="text-secondary" style={{ textDecoration: 'line-through' }}>
                                <NumericFormat
                                    value={item.previousPrice}
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix="$"
                                    decimalScale={2}
                                />
                            </div>}
                        </td>
                        <td>{item.categoryNames?.join(', ')}</td>
                        <td><img src={item.imageUrl} style={{ maxWidth: 100, maxHeight: 100 }} /></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    }

    return view;
}

export default FeedList;
