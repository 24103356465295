import { Col, Nav, Row, Tab } from "react-bootstrap";

function NavPage(props: {
    items: { label: string, key: string }[]
    children: { [index: string]: any }
    pageTitle?: string
    tab?: string
}) {

    return (
        <>
            {props.pageTitle ? <Row className="mb-3">
                <Col xs={12}>
                    <h1>{props.pageTitle}</h1>
                    <hr />
                </Col>
            </Row> : null}
            <Tab.Container id="left-tabs-example" defaultActiveKey={props.tab || props.items[0].key}>
                <Row>
                    <Col xs={12} md={2}>
                        <Nav variant="pills" className="flex-column">
                            {props.items.map(item => <Nav.Item key={item.key}>
                                <Nav.Link eventKey={item.key}>{item.label}</Nav.Link>
                            </Nav.Item>
                            )}
                        </Nav>
                    </Col>
                    <Col xs={12} md={10}>
                        <Tab.Content>
                            {props.items.map(item => <Tab.Pane key={item.key} eventKey={item.key}>{props.children[item.key]}</Tab.Pane>)}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
}

export default NavPage;
